export const en = {
  availability: {
    tentative: 'Tentative',
    free: 'Free',
    reserved: 'Reserved',
    closed: 'Closed',
    freeClosed: 'Free (Closed)',
    reservedClosed: 'Reserved (Closed)',
    notReservableNow: 'Not reservable now',
  },
  scheduler: {
    calendars: 'Calendars',
    myCalendars: 'My calendars',
    primaryCalendar: 'Private calendar',
    otherUsers: 'Other users',
    resources: 'Locations',
    addCalendar: 'Add calendar',
    cancel: 'Cancel',
    placeholderSearch: 'Search by name, e-mail...',
    list: 'List',
    calendar: 'Calendar',
    view: 'View',
    allDay: 'All day',
    today: 'Today',
    next: 'Next',
    previous: 'Previous',
    dayView: 'Day view',
    monthView: 'Month view',
    weekView: 'Week view',
  },
  workingHours: {
    open: 'Open',
    openEvent: 'Open when the event starts',
    closed: 'Closed',
    closedEvent: 'Closed when the event starts',
    whenOpens: '(opens in <b>{{hours}} hours, {{minutes}} minutes</b>)',
    whenOpensEvent: '(opens <b>{{hours}} hours, {{minutes}} minutes</b> later)',
    monday: 'Monday',
    tuesday: 'Tuesday',
    wednesday: 'Wednesday',
    thursday: 'Thursday',
    friday: 'Friday',
    saturday: 'Saturday',
    sunday: 'Sunday',
    differentTimezone: 'You are in different timezone. Open hours are based on timezone of the resource.',
    locationTimezone: 'Location timezone:',
  },
  settings: {
    locale: {
      title: 'Language and localization',
      firstDayOfWeek: 'First day of the week',
    },
    locations: {
      title: 'Home area and locations filtering',
      description: 'Settings for filtering.',
      homeArea: 'Home area',
      homeAreaDescription: 'If you have a favourite area, such as a building or floor, you can set it as the default for filtering.',
      filteredAreas: 'Allowed areas',
      filteredAreasDescription: 'Only locations that are located in these areas will be offered in reservation search results',
      save: 'Save',
    },
  },
  account: {
    unsuccessfulLogin: 'Login was unsuccessful',
    wrongCreddentials: 'Wrong credentials - check your username and password. User may be deactivated.',
    unknownError: 'Unknown error - please try again later',
  },
  findCol: {
    find: 'Search the colleague you are willing to reserve a location next to:',
    hint: "Please use search above to show the colleague's events",
  },
  qr: {
    unrecognizedTitle: 'Unrecognized QR code',
    unrecognizedBody: 'We cannot recognize the QR code. Are you sure it belongs to the CUE Solution?',
    text: 'Scan QR code to open reservation, or to confirm your reservation',
    shame: 'Error occurs, please try the operation again later.',
    canceled: 'Your reservation was cancelled successfully',
    confirm: 'Confirm your reservation',
    confirmDelete: 'Are you sure you want to Cancel your reservation?',
    confirmDeleteDialog: 'This reservation will be canceled.',
    confirmed: 'Your reservation is confirmed now.',
    confirmFail: 'Unable to confirm the event, please contact the reservation system administrator.',
    detail: 'Reservation detail',
  },
  leftMenu: {
    duration15: 'Minimal duration is 15 minutes',
    optional: 'Optional',
    remotely: 'Remotely',
    attendees: 'Attendees',
    addMe: 'Add me',
    accept: 'Set',
    cancel: 'Cancel',
    date: 'Date',
    time: 'Time',
    today: 'Today',
    filter: 'Filter',
    from: 'From',
    to: 'To',
    duration: 'Required duration',
    name: 'Name',
    searchByName: 'Search by name',
    area: 'Area',
    type: 'Type',
    capacity: 'Capacity',
    reset: 'Reset',
    group: 'Group',
    closeFilters: 'Close filters',
    openFilters: 'Open filters',
  },
  dropdowns: {
    attendeesHint: 'Search by name or email address.',
  },
  resourceDetail: {
    defaultTitle: 'Reservation in {resource-display-name} from {start-date} {start-time} to {end-date} {end-time}.',
    pleaseJoinMe:
      'Hello, here is an invitation to the meeting in {resource-display-name} from {start-date} {start-time} to {end-date} {end-time}.',
    cancel: 'Cancel',
    capacity: 'Capacity',
    type: 'Type',
    name: 'Name',
    qrCategoryName: 'Category',
  },
  unitDetail: {
    cueDevice: 'CUE device',
    magicName: 'Magic name',
    status: 'Status',
    lastActive: 'Last active',
    serialNumber: 'Serial number',
    firmware: 'Firmware',
    ipAddress: 'IP address',
    screenshot: 'No-interactive screenshot',
    lastDownloaded: 'Last downloaded screenshot',
    online: 'Online',
    offline: 'Offline',
  },
  secondMenu: {
    foundResourceByName: 'Found locations',
    group: 'Group',
    capacity: 'Capacity',
    occupancy: 'Occupancy',
    name: 'Name',
    ascending: 'ascending',
    descending: 'descending',
  },
  common: {
    contactToCheckAccount: 'Plese contact your system administrator to check your account settings.',
    internalError: 'Internal server error - please contact your server administrator',
    notFoundResource: 'Location has not been found.',
    notFoundReservation: 'Reservation has not been found.',
    available: 'Available',
    availableOutsideWorkingHours: 'Outside working hours',
    reserved: 'Reserved',
    closed: 'Closed',
    limited: 'Limited',
    assignedToYou: 'Yours',
    assignedToOthers: 'Others',
    accepted: 'Accepted',
    cancelled: 'Cancelled',
    unconfirmed: 'Unconfirmed',
    rejected: 'Rejected',
    tentative: 'Tentative',
    refresh: 'Refresh',
    reserve: 'Reserve',
    starts: 'starts',
    ends: 'ends',
    start: 'start',
    reservations: 'Reservations',
    not_available: 'Not available',
    onLater: 'soon',
    now: 'now',
    quickReserve: 'Favourite',
    placeholders:
      'You can use placeholders: {resource-url} {resource-display-name} {start-date} {start-time} {end-date} {end-time} {resource-capacity} {attendees-count}. They will be replaced for real data of event.',
    cancelledUnconfirmed: 'Cancelled - Unconfirmed',
  },
  menu: {
    news: 'News',
    dashboard: 'Home',
    reserve: 'New reservation',
    timeline: 'Timeline',
    floorplans: 'Floorplans',
    findFindColleague: 'Find a colleague',
    reservations: 'All reservations',
    navigation: 'Navigate',
    settings: 'Settings',
    profile: 'Profile',
    collapseMenu: 'Collapse lock',
    orders: 'My orders',
    logout: 'Log Out',
    reserveButton: 'New reservation',
    ordersandservices: 'My orders and requests',
  },
  login: {
    or: 'or',
    linkAndLogin: 'Login and link accounts',
    pairViaEmailLogin: 'Pair via e-mail login',
    notPaired:
      'There is no internal CUE Solution user account for this account. Please log in with your internal user account to pair accounts.',
    goToNavigationApp: 'Navigation app',
    newsError: 'Could not load the news',
    subtitle: 'The smart buildings solution for your business, reinvented',
    welcome: 'Welcome back!',
    loginWith: 'Login with',
    language: 'Language',
    copyright: 'Copyright',
    producedBy: 'Produced by',
    continue: 'Continue',
    loginViaEmail: 'Login',
    emailLoginTitle: 'Login via e-mail - one time code',
    emailLoginSessionCode:
      'If the e-mail address you had entered in the previous step was valid, an e-mail containing the one-time code has been send to that address',
    accessCodeSent: 'We have sent you the access code to your e-mail',
    requestId: 'RequestID',
    codeFromEmail: 'Code from e-mail',
    missingRequestId: 'Missing parameter - please start again by pressing the button below',
    backToLogin: 'Back to login',
    back: 'Back',
    notReceivedEmail:
      "Didn't receive an email? Please check your email address or <a href='#' class='go-back'>go back</a> and try to send the email again.",
    version: 'Version',
  },
  deletePopup: {
    title: 'Delete event',
    question: 'Do you really want to delete event',
    delete: 'Delete',
    close: 'Close',
  },
  cancelParticipationPopup: {
    title: 'Cancel participation on the event',
    question: 'Do you really want to cancel your participation on the event',
    cancel: 'Cancel participation',
    close: 'Close',
  },
  reservationPopup: {
    addMeToReservation: 'Add me to the reservation',
    copied: 'Copied to clipboard',
    visibility: {
      title: 'Visibility of the event',
      public: 'Public',
      private: 'Private',
    },
    eventShow: 'Show event in calendar as:',
    free: 'Free',
    workingElsewhere: 'Working elsewhere',
    tentative: 'Tentative',
    busy: 'Busy',
    away: 'Away',
    addToFavourite: 'Add to favourite',
    removeFromFavourites: 'Remove from favourite',
    title: 'Reservation detail',
    location: 'Location',
    name: 'Title',
    description: 'Description',
    onlineMeeting: 'Online meeting',
    duration: 'Duration',
    start: 'Start',
    allDay: 'All day',
    end: 'End',
    attendees: 'Attendees',
    optionalAttendees: 'Optional attendees',
    requiredAttendees: 'Required attendees',
    organizer: 'Organizer',
    close: 'Close',
    reservation: 'Reservation',
    detail: 'Location detail',
    change: 'change',
    other: 'Unfortunately, it is not possible to reserve this location as it is assigned to other user(s).',
    none: 'None',
    navigationHint: 'Navigation support link',
    navigationHintDescription:
      'A link to navigate to the meeting location can be automatically added to the event description. It can lead to the anonymous CUE Navigation application, the internal CUE Assist application available after login, or to both applications.',
    both: 'Both Apps',
    newReservation: 'New reservation',
    reminder: {
      title: 'Remind event',
      dontRemind: 'Do not remind',
      justInTime: 'At time of event',
      fiveMinutes: '5 minutes before',
      tenMinutes: '10 minutes before',
      fifteenMinutes: '15 minutes before',
      thirtyMinutes: '30 minutes before',
      oneHour: '1 hour before',
      twoHours: '2 hours before',
      twelveHours: '12 hours before',
      oneDay: '1 day before',
      oneWeek: '1 week before',
      customMinutesBefore: '{{minutes}} minutes before',
    },
  },
  dashboard: {
    showOnFloorplan: 'Show on floorplan',
    quickReserveExplain:
      'If you wish to be able to reserve quickly, add any room or other location to your favourites in the New reservation window.',
    confirm: 'Confirm',
    noEvents: 'You have no reservation',
    eventDetail: 'event detail',
    deleteEvent: 'delete event',
    logout: 'logout',
    myReservations: 'Reservations',
    cancelParticipation: 'Cancel participation',
  },
  finishEvent: {
    finish: 'Finish',
    finishEvent: 'Finish the event',
    question: 'Do you really wish to end the event? The end of this event will be set to NOW.',
  },
  reservations: {
    organiserAndAttendees: 'Organizer & attendees',
    disableMinimumDuration: 'It is required to reserve this resource at least for {{duration}} minutes',
    disableMaximumDuration: 'It is required to reserve this resource at most for {{duration}} minutes',
    disableAvailability: 'Location is not available for a part of the requested time range',
    disablePast: 'It is not possible to reserve for the time period, which is entirely in the past',
    eventsToConfirm: 'Events to confirm',
    noEventsToConfirm: 'No events to confirm',
    toNavigation: 'To navigation',
    navigateTo: 'Navigate to',
    navigateFrom: 'Navigate from',
    all: 'All Reservations',
    allNews: 'All news',
    from: 'From',
    to: 'To',
    resourceName: 'Location name',
    attendees: 'Attendees',
    state: 'State',
    clearAllFilters: 'Clear all filters',
    reservations: 'Reservations',
    filters: 'Filters',
    confirmationRequiredUntil: 'Confirmation until {{until}} is required',
    confirmed: 'Confirmed at  {{when}} by {{who}}',
  },
  guest: {
    text: 'Guests are not allowed to use CUE Assist, contact your system administrator, to import you as legit user',
    logout: 'Logout',
  },
  navigation: {
    resetAfter: 'RESET AFTER',
    confirmType: 'CONFIRM MY LOCATION',
    finish: 'Finish',
    toNextArea: 'To next area',
    placeholder: 'Search or select location...',
    displayName: 'Display name',
    location: 'Location',
    actions: 'Actions',
    categories: 'Categories',
    restartNavigation: 'Reset navigation',
    finished: 'Finish',
    arrivedToDest: 'You have arrived to your destination!',
    close: 'Close',
    newReservation: 'New Reservation',
    startStepByStepNav: 'Start step-by-step navigation',
    backToNavOverviewMap: 'Back to route overview',
    notEnabled: 'Navigation is not enabled',
    notValidKiosk: 'Invalid kiosk',
    notValidKioskLicence: 'Invalid kiosk licence',
    clearTypeFilter: 'type',
    clearCategoryFilter: 'category',
    clearAreaFilter: 'area',
    sortNearest: 'From the nearest',
    sortABC: 'Alphabetically',
    categoriesFilters: 'Categories',
    typesFilters: 'Types',
    areaFilters: 'Areas',
    confirmFilters: 'Confirm',
    cancelFilters: 'Cancel',
    filters: 'Filters',
    youAreHere: 'YOU ARE HERE',
    navigateTo: 'Navigate to',
    showRoute: 'Show route',
    description: 'Description:',
    pois: 'POIs',
    selectAsStart: 'select as start',
    navigationTo: 'Navigation to',
    closeRoute: 'Close route',
    backTo: 'Back to',
    to: 'to',
    openDetail: 'open detail',
    searchLocation: 'Search for location',
    barrierFree: 'Prefer barrier-free paths',
    noBarrierFreeFoundTitle: 'No barrier-free route',
    noBarrierFreeFoundText: 'No barrier-free route has been found.',
    noBarrierFreeFoundQuestion: 'Would you like to view a barrier route?',
    changeTarget: 'Change target',
    barrierRoute: 'Barrier route',
    scanAndOpenUrlInMobile: 'Scan and open website in your mobile.',
    mapItemDetailNotFoundHeader: 'Not found',
    mapItemDetailNotFoundText: 'Error 404 - not found',
  },
  profile: {
    alias: 'Provider',
    paired: 'Paired',
    email: 'Email',
    unpair: 'Unpair account',
    linkedAccounts: 'Linked accounts',
    settings: 'Settings',
    version: 'Version',
    tabGeneral: 'General',
    tabIntegrations: 'Integrations',
    tabError: 'Error reporting',
    headingProfile: 'Profile',
    profileName: 'Name',
    profileSurename: 'Surname',
    profileAddress: 'Address',
    profileEmail: 'E-mail',
    language: 'Language',
    integrationsLinked: 'Linked',
    integrationsNotLinked: 'Not-linked',
    integrationsConnected: 'You are connected!',
    integrationsNotConnected: 'You are not connected.',
    integrationsButtonDisconnect: 'DISCONNECT',
    integrationsButtonConnect: 'CONNECT',
    integrationsButtonSave: 'SAVE',
    integrationsText:
      'Connect CUE Assist to a variety of third-party providers. You can connect it to various online meeting providers such as Zoom or Webex. More integrations will be added in the future.',
  },
  webex: {
    defaultSettingsHeading: 'Default meeting settings',
    defaultSettingsDescription: 'You can use following placeholders in text inputs:',
    defaultSettingsInputTitle: 'Title',
    defaultSettingsInputTitleDescription: 'Will be used as Webex Online Meeting Title. Max. 128 characters',
    defaultSettingsInputTitleErrorMandatory: 'This field is mandatory!',
    defaultSettingsInputDescription: 'Description',
    defaultSettingsInputDescriptionDescription: 'Will be used as Webex Online Meeting Agenda. Max. 1200 characters',
    defaultSettingsInputPassword: 'Password',
    defaultSettingsInputPasswordDescription: 'If left empty, password will be generated by Webex',
    defaultSettingsInputPublic: 'Public',
    defaultSettingsInputPublicDescription: 'Is the meeting public?',
    defaultSettingsInputSendemail: 'Send Webex Meeting e-mail',
    defaultSettingsInputSendemailDescription: 'Do you want to send Webex e-mail to attendees when a meeting is created?',
    defaultSettingsInputBody: 'Body',
    defaultSettingsInputBodyDescription:
      'This part will be attached to Calendar Meeting BODY. Use this to attach Webex meeting info to main email sent to attendees.',
    notPaired: 'You have not paired your Webex account yet, go to your profile settings, select tab Integrations and open panel Webex.',
  },
  orders: {
    notAvailable: 'It is not possible to add any request to this resource',
    saveOnly: 'Save',
    saveOnlyAndOrder: 'Save & Order',
    created: 'Created',
    eventStart: 'Event start',
    loadMore: 'Load more',
    list: 'List',
    floorplan: 'Floorplan',
    orderButton: 'Request service',
    reserveOnly: 'Reserve',
    changeOnly: 'Change',
    reserveAndOrder: 'Reserve & Order',
    changeAndOrder: 'Change & Order',
    newOrder: 'Order',
    newRequest: 'Request',
    newOrderTo: 'Request service to',
    newOrderToEvent: 'Order to',
    orders: 'Orders',
    serviceRequests: 'Service requests',
    orderNewTitle: "Select request's location",
    filterResources: 'Locations',
    filterServices: 'Items',
    filterEventOrders: 'Orders',
    filterResourceOrders: 'Service requests',
    countOrderedServices: '{{count}} ordered items',
    note: 'Note',
    newOrderTitle: 'New order',
    newServiceInstruction: 'To create new service request please add at least one item',
    newServiceTitle: 'New service request',
    newServiceRequest: 'New service request',
    newOrderInstruction: 'To create new order please add at least one Order item',
    addItem: 'Add item',
    noDescription: 'No description',
    minimalLength: 'Please enter a minimum of {{length }} character.',
  },
  wayfinding: {
    changeButton: 'change',
    directionsWindowHeading: 'Directions',
    goBack: 'Go back',
    titleScanQr: 'SCAN THE QR CODE TO SELECT YOUR STARTING LOCATION',
    buttonTypeQr: 'TYPE QR',
    descriptionTypeQr:
      'Type {{digitCodeNumbers}}-digit code you can find next to the QR code to set the starting location for the navigation',
    buttonSearch: 'SEARCH',
    buttonScanQr: 'SCAN QR',
    buttonRestart: 'Reset',
    buttonClose: 'Close',
    buttonList: 'Select from list',
    continuousLeft: 'Keep going to the <strong>left</strong>',
    continuousSuperLowLeft: 'Keep going to the <strong>very slightly left</strong>',
    continuousStraight: 'Keep going <strong>straight</strong>',
    continuousRight: 'Keep going to the <strong>right</strong>',
    continuousLowLeft: 'Keep going to the <strong>slightly left</strong>',
    continuousSuperLeft: 'Keep going to the <strong>absolutely left</strong>',
    continuousLowRight: 'Keep going to the <strong>slightly right</strong>',
    continuousSuperRight: 'Keep going to the <strong>absolutely right</strong>',
    Left: 'turn <strong>left</strong>',
    SuperLowLeft: 'turn <strong>very slightly left</strong>',
    SuperLowRight: 'turn <strong>very slightly right</strong>',
    Straight: 'go <strong>straight</strong>',
    Right: 'turn <strong>right</strong>',
    LowLeft: 'turn <strong>left</strong>',
    SuperLeft: 'turn <strong>absolutely left</strong>',
    LowRight: 'turn <strong>right</strong>',
    SuperRight: 'turn <strong>absolutely right</strong>',
    toQrCode: ' to the <strong>QR-{{code}} </strong>',
    isLast: ' to the target destination.',
    normal:
      'Keep going for <strong>{{distance}}m</strong>, reach <strong>{{areaMetricName}}</strong>, escape to the <strong>{{startPortalName}}</strong> and move to the area <strong>{{endPortalName}}</strong>.',
    normalNext: 'Keep going straight for <strong>{{distance}}m</strong> {{qrCodeText}} {{portalText}},then {{angleText}}',
    continuous: '{{text}} {{portalText}}, continue in this direction for <strong>{{distance}}m</strong>{{qrCodeText}}',
    toQrCodeContinuous: ' you will see <strong>the QR-{{code}}</strong>',
    nextAngle: 'Then - {{angleText}}',
    codeNotFound: 'The QR code has not been found.',
    server500: 'Server error. Try again later.',
    notFound: 'Route not found',
    barrierFreenotFound: 'Barrier free route not found',
    startNotFound: 'Start not found',
    targetNotFound: 'Target not found',
    startAndTargetNotFound: 'Start and Finish not found',
    continueWithWebNav: 'Continue with mobile app',
    restartHeading: 'Do you want to reset navigation?',
    sameStartAsTarget: 'You have entered the same start and finish.',
    sameStartAsTargetDescription: 'Change one of the navigation points.',
    routeNotExisting: "Unfortunately we can't find navigation for your route.",
    routeNotExistingDescription: 'Check your route and change one of the navigation points.',
    restartDescription: "You'll lose all navigation progress. Start and finish will be deleted.",
    or: 'or...',
    orCategory: 'or choose the category',
    orCategoryTempText: 'or to the nearest of the category',
    selectStartLocation: 'Select start location',
    selectTargetLocation: 'Select target location',
    start: 'Start',
    target: 'Target',
    touchMe: 'Touch me',
  },
  errorReporting: {
    clearLogs: 'Clear Logs',
    download: 'Download',
    errorReportingHeading: 'Error reporting',
    panelbarErrors: 'Errors',
    panelbarUnprocessedEvents: 'Unprocessed events',
  },
  notFound: {
    heading: 'Page not found',
    dashboardButton: 'GO TO DASHBOARD',
    resourceNotFound: 'Location has not been found',
    orderNotFound: 'This reservation has no orders',
  },
  pager: {
    visible: 'Visible:',
    fromCount: 'from {{total}} items total',
  },
  reservationOption: {
    timelineDescription: 'A grid of squares to represent occupancy of rooms',
    floorplansDescription: "Graphical representation of a building's floorplan",
  },
  outlookAddin: {
    cannotFetchError: 'Cannot fetch data about event from the calendar server.',
    cannotFetchErrorHint: 'This usually happens when the Outlook user is different than Addin logged user?',
    errorDescription: 'Error has occured during the communication with the server',
    refresh: 'Refresh',
    userIsLoggedIn: 'The user has been authorised. You can close this window.',
    loginUserInfo: 'Welcome, please login to continue.',
    login: 'Login',
    logout: 'Logout',
    noResourceOnEvent: 'No locations found in the event.',
    chooseResource: 'Add to event',
    activeFilterCount: 'active filter groups',
    removeResource: 'Remove from event',
    openFilters: 'open filters',
    closeFilters: 'close filters',
    resetFilters: 'reset',
    onlyAvailable: 'Only available',
    allResources: 'All locations',
    cueExternalLink: 'Link to CUE Assist',
    cueSearch: 'Open detail',
    orders: 'Orders',
    locationDetail: 'Location detail',
    assignedMine: 'Assigned to me',
    assignedOurs: 'Assigned to us',
    assignedRestricted: 'Restricted',
    assignedFree: 'No restrictions',
    availability: 'Availability',
    available: 'Available',
    unavailable: 'Unavailable',
    changeLocation: 'Change location',
    resourceRestricted: 'This location is restricted.',
    chooseAnotherResource: 'Please choose another location',
    noSubject: 'No subject',
    differentOutlookAndAddinUser: 'The user in Outlook and CUE Addin must be the same',
    onlyAvailibleResourcesNotFound: 'No available location found',
    resourcesNotFound: 'No location found',
  },
  resource: {
    name: 'Name',
    alternativeName: 'Alternative name',
  },
};
